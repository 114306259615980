import {trackEvent} from '@hconnect/common/logging/Analytics'
import {AxiosInstance, AxiosResponse} from 'axios'
import fileDownload from 'js-file-download'
import {get} from 'lodash'
import moment from 'moment'
import {AnyAction} from 'redux'
import {ThunkAction} from 'redux-thunk'

import {errorKeyFromRequestError} from '../ErrorMap'
import {errorsAdd} from '../Organisms/Errors'
import {selectOrders} from '../Organisms/Orders'
import {AppState} from '../Root.store'
import {getUserProfile} from '../UserProfile/UserProfile.selectors'

import {
  ASYNC_JOB_ADD,
  ASYNC_JOB_ADD_TO_HISTORY,
  ASYNC_JOB_DELETE,
  ASYNC_JOB_REPORT,
  ASYNC_JOB_UPDATE,
  ApiDownloadRequestFormats,
  ApiDownloadRequestStatuses,
  ApiDownloadRequestTypes,
  AsyncJobQueueActionTypes,
  DownloadRequestPost,
  JobEndpoint,
  JobType,
  SHEET_DOWNLOAD_FAILURE,
  SHEET_DOWNLOAD_PENDING,
  SHEET_DOWNLOAD_SUCCESS,
  SHEET_REQUEST_FAILURE,
  SHEET_REQUEST_PENDING,
  SHEET_REQUEST_SUCCESS,
  SheetRequestType
} from './AsyncJobQueue.types'
import {isJobInHistory} from './asyncjobqueue.utils'

export const addJob = (data: JobType): AsyncJobQueueActionTypes => ({
  type: ASYNC_JOB_ADD,
  data
})

export const reportJob = (id: string): AsyncJobQueueActionTypes => ({
  type: ASYNC_JOB_REPORT,
  id
})

export const updateJob = (data: any): AsyncJobQueueActionTypes => ({
  type: ASYNC_JOB_UPDATE,
  data
})

export const addJobToHistory = (data: JobType): AsyncJobQueueActionTypes => ({
  type: ASYNC_JOB_ADD_TO_HISTORY,
  data
})
export const deleteJob = (id: string, name?: string): AsyncJobQueueActionTypes => ({
  type: ASYNC_JOB_DELETE,
  id,
  name
})
const sheetRequestPending = (data: any): AsyncJobQueueActionTypes => ({
  type: SHEET_REQUEST_PENDING,
  data
})
const sheetRequestSuccess = (data: any): AsyncJobQueueActionTypes => ({
  type: SHEET_REQUEST_SUCCESS,
  data
})
const sheetRequestFailure = (data: any): AsyncJobQueueActionTypes => ({
  type: SHEET_REQUEST_FAILURE,
  payload: data
})

export const sheetRequest =
  ({
    criteria = {
      startDate: moment().subtract(30, 'days'),
      endDate: moment().add(5, 'days')
    },
    type = ApiDownloadRequestTypes.invoiceDeliveryExport,
    format = ApiDownloadRequestFormats.xlsx,
    name = `my_filename.${format}`,
    fileType,
    dateRangeInDays,
    startTimeStamp,
    endTimeStamp,
    fullName,
    email,
    sendEmail,
    country,
    url,
    shortName,
    jobId,
    clientTimezone,
    analytics,
    exportSettings
  }: SheetRequestType): ThunkAction<Promise<void>, AppState, {api: any}, AnyAction> =>
  async (dispatch, getState, {api}): Promise<void> => {
    const state = getState()
    const locale = get(getUserProfile(state), 'userProfile.defaultLocale')
    const {orderStatus} = selectOrders(state).filters
    if (orderStatus) criteria['orderStatus'] = orderStatus
    const data = {criteria, type}
    dispatch(sheetRequestPending(data))

    try {
      const response: AxiosResponse<DownloadRequestPost> = await api.post('/downloadRequests', {
        criteria,
        type,
        format,
        name: shortName,
        locale,
        fullName,
        email,
        sendEmail,
        country,
        url,
        clientTimezone,
        analytics,
        exportSettings
      })

      dispatch(sheetRequestSuccess(data))
      const downloadRequestId = get(response, ['data', 'id'], '')
      dispatch(
        addJob({
          id: downloadRequestId,
          jobId,
          status: ApiDownloadRequestStatuses.pending,
          name,
          type: JobEndpoint.Export,
          fileType,
          startTimeStamp,
          endTimeStamp,
          dateRangeInDays,
          fileSize: undefined,
          requestTimestamp: Date.now(),
          downloadTimeStamp: 0,
          secondsElapsed: 0,
          downloadType: type
        })
      )
    } catch (err) {
      const errorKey = errorKeyFromRequestError(err)
      dispatch(
        errorsAdd({
          key: 'invoice',
          translationKey: `errorMessages.${errorKey}`,
          callback: () =>
            dispatch(
              addJob({
                jobId,
                status: ApiDownloadRequestStatuses.pending,
                name,
                type: JobEndpoint.Export,
                fileType,
                startTimeStamp,
                endTimeStamp,
                dateRangeInDays,
                fileSize: undefined,
                requestTimestamp: Date.now(),
                downloadTimeStamp: 0,
                secondsElapsed: 0
              })
            ),
          callbackTranslationKey: 'error.action.retry'
        })
      )

      dispatch(sheetRequestFailure(err))
    }
  }
const sheetDownloadPending = (data) => ({
  type: SHEET_DOWNLOAD_PENDING,
  data
})
const sheetDownloadSuccess = (data) => ({
  type: SHEET_DOWNLOAD_SUCCESS,
  data
})
const sheetDownloadFailure = (data) => ({
  type: SHEET_DOWNLOAD_FAILURE,
  payload: data
})
export const downloadSheet =
  (
    documentId: string,
    fileName?: string,
    analytics?: {jobId?: string; numberOfDaysSelected?: string}
  ) =>
  async (dispatch, getState, {api}) => {
    const name = fileName || 'document'
    try {
      const response: AxiosResponse<Buffer> = await api(`/downloadRequests/${documentId}/file`, {
        responseType: 'blob'
      })
      const contentType = response.headers['content-type']
      const blob = new Blob([response.data], {type: contentType})
      fileDownload(blob, name)
      const isBulkDownload = name.split('.')[1] === (ApiDownloadRequestFormats.zip as string)
      const eventName = isBulkDownload ? 'hubBulkExportdownloadbyemail' : 'hubExportSuccess'
      trackEvent(eventName, {
        product: 'hub',
        numberOfDaysSelected: analytics?.numberOfDaysSelected || '',
        jobId: analytics?.jobId || '',
        initiationSource: 'email',
        fileSize: blob.size
      })
    } catch (err) {
      dispatch(addJob({name: 'downloadFailed', status: ApiDownloadRequestStatuses.failed}))
      const errorKey = errorKeyFromRequestError(err)
      dispatch(
        errorsAdd({
          key: 'invoice',
          translationKey: `errorMessages.${errorKey}`,
          callback: () => dispatch(downloadSheet(documentId, fileName)),
          callbackTranslationKey: 'error.action.retry'
        })
      )
    }
  }
export const sheetDownload =
  (
    documentId: string,
    fileName: string,
    job: JobType,
    jobHistory: JobType[]
    // eslint-disable-next-line max-params
  ): ThunkAction<Promise<void>, AppState, {api: AxiosInstance}, AnyAction> =>
  async (dispatch, getState, {api}) => {
    if (job.id && !isJobInHistory(job.id, jobHistory)) {
      dispatch(sheetDownloadPending(documentId))

      try {
        const response: AxiosResponse<Buffer> = await api(`/downloadRequests/${documentId}/file`, {
          responseType: 'blob'
        })
        const contentType = response.headers['content-type']
        const blob = new Blob([response.data], {type: contentType})
        fileDownload(blob, fileName)
        dispatch(sheetDownloadSuccess(documentId))
        const downloadFinished = Date.now()
        dispatch(
          addJobToHistory({
            ...job,
            fileSize: blob.size,
            status: ApiDownloadRequestStatuses.done,
            downloadTimeStamp: downloadFinished,
            secondsElapsed:
              job.requestTimestamp && Math.floor((downloadFinished - job.requestTimestamp) / 1000)
          })
        )
      } catch (err) {
        const errorKey = errorKeyFromRequestError(err)
        dispatch(
          errorsAdd({
            key: 'invoice',
            translationKey: `errorMessages.${errorKey}`,
            callback: () => dispatch(sheetDownload(documentId, fileName, job, jobHistory)),
            callbackTranslationKey: 'error.action.retry'
          })
        )

        dispatch(sheetDownloadFailure(err))
      }
    }
  }
