// Map HTTP STATUS_CODE and TYPE in the response body to a unique key
// that can be used to get a translation, e.g. t('errorMessages.400_PROBLEM_CREATING_USER')
// See https://api.hce.heidelbergcement.com/API#header-error-object

// FIXME: this should be the same for authenticator. Revisit when mono-repo is in place
import {get} from 'lodash'

export const InternalErrors = {
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  UNMAPPED_STATUS_AND_TYPE: 'UNMAPPED_STATUS_AND_TYPE',
  // the server did not respond at all and therefore did not send status and type
  NETWORK_ERROR: 'NETWORK_ERROR',
  // the jwt is bad
  BAD_TOKENINFO_ERROR: 'BAD_TOKENINFO_ERROR',
  FIELD_REQUIRED: 'FIELD_REQUIRED'
}

// FIXME: ApiErrors should be shared among all projects, revisit when MonoRepo is ready
// FIXME: rename fields analogously from '301_MOVED_PERMANENTLY' to ERROR_301_MOVED_PERMANENTLY to allow dot referencing
export const ApiErrors = {
  '301_MOVED_PERMANENTLY': 'ERROR_301_MOVED_PERMANENTLY',
  '400_BAD_REQUEST': '400_BAD_REQUEST',
  '400_REQUEST_ERROR': '400_REQUEST_ERROR',
  '400_PROBLEM_CREATING_USER': '400_PROBLEM_CREATING_USER',
  '400_LOGIN_FAILURE': '400_LOGIN_FAILURE',
  '400_UPDATE_USER_EMAIL_IN_USE': '400_UPDATE_USER_EMAIL_IN_USE',
  '400_RESET_PASSWORD': '400_RESET_PASSWORD',
  '400_GET_USER': '400_GET_USER',
  '401_UNAUTHORIZED': '401_UNAUTHORIZED',
  '401_SHARE_PERMISSIONS_FAILED': '401_SHARE_PERMISSIONS_FAILED',
  '401_LOGIN_FAILURE': '401_LOGIN_FAILURE',
  '401_REFRESH_FAILURE': '401_REFRESH_FAILURE',
  '401_ACCOUNT_LOCKED': '401_ACCOUNT_LOCKED',
  '403_FORBIDDEN': '403_FORBIDDEN',
  '403_USER_ALREADY_EXISTS': '403_USER_ALREADY_EXISTS',
  '403_CHANGE_PASSWORD': '403_CHANGE_PASSWORD',
  '403_GET_USER': '403_GET_USER',
  '403_PASSWORD_POLICY': '403_PASSWORD_POLICY',
  '404_NOT_FOUND': '404_NOT_FOUND',
  '404_RESET_PASSWORD': '404_RESET_PASSWORD',
  '404_CHANGE_PASSWORD': '404_CHANGE_PASSWORD',
  '404_GET_USER': '404_GET_USER',
  '429_TOO_MANY_REQUESTS': '429_TOO_MANY_REQUESTS',
  '500_INTERNAL_SERVER_ERROR': '500_INTERNAL_SERVER_ERROR',
  '502_BAD_GATEWAY': '502_BAD_GATEWAY',
  '503_SERVICE_UNAVAILABLE': '503_SERVICE_UNAVAILABLE',
  '504_GATEWAY_TIMEOUT': '504_GATEWAY_TIMEOUT'
}

// See docs https://api.hce.heidelbergcement.com/API#header-errors
export const HttpStatusAndErrorTypeToUniqueKey = {
  301: {DEFAULT: ApiErrors['301_MOVED_PERMANENTLY']},

  400: {
    DEFAULT: ApiErrors['400_BAD_REQUEST'],
    'https://api.hce.heidelbergcement.com/errors/requestError': ApiErrors['400_REQUEST_ERROR'],
    'https://api.hce.heidelbergcement.com/errors/problemCreatingUser':
      ApiErrors['400_PROBLEM_CREATING_USER'],
    'https://api.hce.heidelbergcement.com/errors/login-failure': ApiErrors['400_LOGIN_FAILURE'],
    'https://api.hce.heidelbergcement.com/errors/updateUser-emailInUse':
      ApiErrors['400_UPDATE_USER_EMAIL_IN_USE'],
    'https://api.hce.heidelbergcement.com/errors/resetPassword': ApiErrors['400_RESET_PASSWORD'],
    'https://api.hce.heidelbergcement.com/errors/changePassword': ApiErrors['400_CHANGE_PASSWORD'],
    'https://api.hce.heidelbergcement.com/errors/getuser': ApiErrors['400_GET_USER']
  },

  401: {
    DEFAULT: ApiErrors['401_UNAUTHORIZED'],
    'https://api.hce.heidelbergcement.com/errors/sharePermissionsFailed':
      ApiErrors['401_SHARE_PERMISSIONS_FAILED'],
    'https://api.hce.heidelbergcement.com/errors/login-failure': ApiErrors['401_LOGIN_FAILURE'],
    'https://api.hce.heidelbergcement.com/errors/refresh-failure': ApiErrors['401_REFRESH_FAILURE'],
    'https://api.hce.heidelbergcement.com/errors/account-locked': ApiErrors['401_ACCOUNT_LOCKED']
  },

  403: {
    DEFAULT: ApiErrors['403_FORBIDDEN'],
    'https://api.hce.heidelbergcement.com/errors/userAlreadyExists':
      ApiErrors['403_USER_ALREADY_EXISTS'],
    'https://api.hce.heidelbergcement.com/errors/changePassword': ApiErrors['403_CHANGE_PASSWORD'],
    'https://api.hce.heidelbergcement.com/errors/getuser': ApiErrors['403_GET_USER'],
    'https://api.hce.heidelbergcement.com/errors/passwordpolicy': ApiErrors['403_PASSWORD_POLICY']
  },

  404: {
    DEFAULT: ApiErrors['404_NOT_FOUND'],
    'https://api.hce.heidelbergcement.com/errors/resetPassword': ApiErrors['404_RESET_PASSWORD'],
    'https://api.hce.heidelbergcement.com/errors/changePassword': ApiErrors['404_CHANGE_PASSWORD'],
    'https://api.hce.heidelbergcement.com/errors/getuser': ApiErrors['404_GET_USER']
  },

  429: {DEFAULT: ApiErrors['429_TOO_MANY_REQUESTS']},
  500: {
    DEFAULT: ApiErrors['500_INTERNAL_SERVER_ERROR'],
    'https://api.hce.heidelbergcement.com/errors/internalServerError':
      ApiErrors['500_INTERNAL_SERVER_ERROR']
  },

  502: {DEFAULT: ApiErrors['502_BAD_GATEWAY']},
  503: {DEFAULT: ApiErrors['503_SERVICE_UNAVAILABLE']},
  504: {
    DEFAULT: ApiErrors['504_GATEWAY_TIMEOUT'],
    'https://api.hce.heidelbergcement.com/errors/gatewayTimeout': ApiErrors['504_GATEWAY_TIMEOUT']
  }
}

export const errorKeyFromRequestError = (err) => {
  if (!err.response) {
    return InternalErrors.NETWORK_ERROR
  }

  const errStatusType = get(err, ['response', 'data', 'type'], 'DEFAULT')
  const errTypeMapping = errStatusType.startsWith('https') ? errStatusType : 'DEFAULT'
  return get(
    HttpStatusAndErrorTypeToUniqueKey,
    [err.response.status, errTypeMapping],
    InternalErrors.UNMAPPED_STATUS_AND_TYPE
  )
}
