export const featuresConfig = {
  OrderCancellation: {
    constraints: [
      {
        country: 'DE',
        businessLines: ['CEM']
      },
      {
        country: 'US',
        businessLines: ['CEM']
      },
      {
        country: 'CA',
        businessLines: ['CEM']
      },
      {
        country: 'GB',
        businessLines: ['CEM', 'AGG']
      }
    ]
  }
}
