export const ASYNC_JOB_ADD = 'ASYNC_JOB_ADD'
export const ASYNC_JOB_DELETE = 'ASYNC_JOB_DELETE'
export const ASYNC_JOB_UPDATE = 'ASYNC_JOB_UPDATE'
export const ASYNC_JOB_REPORT = 'ASYNC_JOB_REPORT'
export const ASYNC_JOB_ADD_TO_HISTORY = ' ASYNC_JOB_ADD_TO_HISTORY'

export const SHEET_REQUEST_PENDING = 'SHEET_REQUEST_PENDING'
export const SHEET_REQUEST_SUCCESS = 'SHEET_REQUEST_SUCCESS'
export const SHEET_REQUEST_FAILURE = 'SHEET_REQUEST_FAILURE'
export const SHEET_DOWNLOAD_PENDING = 'SHEET_DOWNLOAD_PENDING'
export const SHEET_DOWNLOAD_SUCCESS = 'SHEET_DOWNLOAD_SUCCESS'
export const SHEET_DOWNLOAD_FAILURE = 'SHEET_DOWNLOAD_FAILURE'

export enum ApiDownloadRequestTypes {
  invoicedDeliveries = 'invoicedDeliveries',
  invoiceDeliveryExport = 'invoiceDeliveryExport',
  orderDeliveryExport = 'orderDeliveryExport',
  bulkOrderDeliveryDownload = 'bulkOrderDeliveryDownload',
  bulkInvoiceDeliveryDownload = 'bulkInvoiceDeliveryDownload',
  bulkMaterialTestDownload = 'bulkMaterialTestDownload',
  materialTestExport = 'materialTestExport'
}
export enum ApiDownloadRequestFormats {
  csv = 'csv',
  xlsx = 'xlsx',
  pdf = 'pdf',
  zip = 'zip'
}
export enum ApiDownloadRequestStatuses {
  pending = 'pending',
  inProgress = 'inprogress',
  done = 'done',
  cancelled = 'cancelled',
  failed = 'failed',
  partiallyFailed = 'partiallyfailed',
  timedout = 'timedout'
}

export type ApiDownoladRequestStatusType =
  | ApiDownloadRequestStatuses.pending
  | ApiDownloadRequestStatuses.inProgress
  | ApiDownloadRequestStatuses.done
  | ApiDownloadRequestStatuses.cancelled
  | ApiDownloadRequestStatuses.failed
  | ApiDownloadRequestStatuses.partiallyFailed
  | ApiDownloadRequestStatuses.timedout

export enum JobEndpoint {
  Document = 'document',
  Export = 'export'
}

export type JobEndpointType = JobEndpoint.Document | JobEndpoint.Export

export interface JobType {
  name: string
  status?: ApiDownoladRequestStatusType
  id?: string
  type?: JobEndpointType
  fileType?: string
  fileSize?: number
  dateRangeInDays?: string
  startTimeStamp?: string
  endTimeStamp?: string
  requestTimestamp?: number
  downloadTimeStamp?: number
  secondsElapsed?: number
  // support for DataPrivacyPolicyAcknowledgment
  dataPrivacyUpdatedOn?: string
  jobId?: string
  channel?: 'email' | 'hub'
  downloadType?: keyof typeof ApiDownloadRequestTypes
}

export interface AddJob {
  type: typeof ASYNC_JOB_ADD
  data: JobType
}

export interface UpdateJob {
  type: typeof ASYNC_JOB_UPDATE
  data: JobType
}

export interface ReportJob {
  type: typeof ASYNC_JOB_REPORT
  id: string
}

export interface AddJobToHistory {
  type: typeof ASYNC_JOB_ADD_TO_HISTORY
  data: JobType
}

export interface DeleteJob {
  type: typeof ASYNC_JOB_DELETE
  id: string
  name?: string
}
export interface SheetRequestPending {
  type: typeof SHEET_REQUEST_PENDING
  data: any
}
export interface SheetRequestSuccess {
  type: typeof SHEET_REQUEST_SUCCESS
  data: any
}
export interface SheetRequestFailure {
  type: typeof SHEET_REQUEST_FAILURE
  payload: any
}
export type AsyncJobQueueActionTypes =
  | AddJob
  | UpdateJob
  | AddJobToHistory
  | ReportJob
  | DeleteJob
  | SheetRequestPending
  | SheetRequestSuccess
  | SheetRequestFailure

export interface SheetRequestType {
  criteria: any
  type: ApiDownloadRequestTypes
  format: ApiDownloadRequestFormats
  name: string
  fileType?: string
  dateRangeInDays?: string
  startTimeStamp?: string
  endTimeStamp?: string
  locale?: string
  fullName?: string
  email?: string
  sendEmail?: boolean
  country?: string
  url?: string
  shortName?: string
  jobId?: string
  clientTimezone?: string
  analytics?: {
    jobId?: string
    initiationSource: 'hub' | 'email'
    numberOfDaysSelected?: string
    exportCombined?: boolean
  }
  exportSettings?: {combined: boolean}
}
export interface AsyncJobQueueState {
  jobs: JobType[]
  jobHistory: JobType[]
  reportedJobs: string[]
}

// Generated by https://quicktype.io

export interface DownloadRequestPost {
  /**
   * JSON object of the search criteria
   */
  criteria?: Criteria
  /**
   * format / file type of the download if the type supports multiple formats
   */
  format?: Format
  /**
   * file name
   */
  name?: string
  /**
   * the type of download to prepare
   */
  type: DownloadRequestType

  locale: string
  fullName: string
  email: string
  sendEmail: boolean
  country: string
  url: string
}

/**
 * JSON object of the search criteria
 */
export interface Criteria {
  /**
   * inclusive end date of the search intervall in formation 2018-06-21T00:00:00Z
   */
  endDate?: string
  /**
   * Identifier of the ShipTo location
   */
  siteId?: string
  /**
   * inclusive start date of the search intervall in format 2018-06-21T00:00:00Z
   */
  startDate?: string
}

/**
 * format / file type of the download if the type supports multiple formats
 */
export enum Format {
  CSV = 'csv',
  Xlsx = 'xlsx'
}

/**
 * the type of download to prepare
 */
export enum DownloadRequestType {
  InvoiceDeliveryExport = 'invoiceDeliveryExport',
  InvoicedDeliveries = 'invoicedDeliveries',
  OrderDeliveryExport = 'orderDeliveryExport'
}
