import qs from 'query-string'
import React, {useCallback, useState} from 'react'
import {useHistory} from 'react-router-dom'

export type UseQueryParamStateReturn<T> = [T, React.Dispatch<React.SetStateAction<T>>]

export const useQueryParamState = <T>(
  defaultValue: Record<string, unknown>,
  mergeDefaultValue = false
): UseQueryParamStateReturn<T> => {
  const history = useHistory()

  const parsedValues = window.location.search
    ? qs.parse(window.location.search, {parseBooleans: true})
    : defaultValue

  let initialValue = {} as T
  if (mergeDefaultValue) {
    initialValue = {...(defaultValue as T), ...(parsedValues as T)}
  } else {
    const defaultValueKeys = Object.keys(defaultValue)
    const parsedValuesKeys = Object.keys(parsedValues)

    const atLeastOneKeyExists = parsedValuesKeys.some((value) => defaultValueKeys.includes(value))
    initialValue = atLeastOneKeyExists ? (parsedValues as T) : (defaultValue as T)
  }

  const [value, setValue] = useState(initialValue)

  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue as T)

      const queryParams = qs.parse(window.location.search)

      history.replace({
        search: qs.stringify(newValue ? {...newValue} : ({...queryParams} as Record<string, any>))
      })
    },
    [history]
  )

  return [value, onSetValue]
}
