import {useMutation, useQueryClient} from 'react-query'

import {api} from '../App.global'

import {DistributedCacheRequest, DistributedCacheResponse} from './type'

export const DistributedStorageKeys = {
  all: () => ['distributedStorage'] as const,
  byKeyIdentifier: (key: string) => [...DistributedStorageKeys.all(), key] as const
}

export type StorageClient = {
  setItem: <T>(key: string, value: T) => Promise<void>
  getItem: <T>(key: string) => Promise<T>
}

export const useDistributedStorageClient = (): StorageClient => {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    ({keyIdentifier, value}: DistributedCacheRequest) => {
      return api.post('/distributeduserstorage', {
        keyIdentifier,
        value
      })
    },
    {
      onSuccess: (data, {keyIdentifier, value}) => {
        queryClient.setQueryData(
          DistributedStorageKeys.byKeyIdentifier(keyIdentifier),
          JSON.parse(value)
        )
      }
    }
  )
  return {
    setItem: (keyIdentifier: string, value: unknown) => {
      return mutation
        .mutateAsync(
          {keyIdentifier, value: JSON.stringify(value)},
          {
            onSuccess: () =>
              queryClient.invalidateQueries(DistributedStorageKeys.byKeyIdentifier(keyIdentifier))
          }
        )
        .then(({data}) => data)
    },
    getItem: <T>(key: string) => {
      return queryClient.fetchQuery(
        DistributedStorageKeys.byKeyIdentifier(key),
        ({queryKey}) => {
          const [, keyIdentifier] = queryKey
          return api
            .get<DistributedCacheResponse>(`/distributeduserstorage?keyIdentifier=${keyIdentifier}`)
            .then((data) => {
              try {
                return data.data?.value ? (JSON.parse(data.data.value) as T) : undefined
              } catch (e) {
                console.warn('Unable to parse json from user distributed storage', e)
              }
              return undefined
            })
        },
        {retry: false}
      )
    }
  }
}
