import {catchPromise} from '@hconnect/common/catchPromise'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import {api} from '../App.global'
import JobBanner from '../Molecules/JobBanner'
import {AppState} from '../Root.store'

import {deleteJob, reportJob, sheetDownload, updateJob} from './AsyncJobQueue.action'
import {selectJobHistory, selectReportedJobs} from './AsyncJobQueue.selectors'
import {ApiDownloadRequestStatuses, JobEndpoint, JobType} from './AsyncJobQueue.types'
import {isJobInHistory} from './asyncjobqueue.utils'

const POLLING_INTERVAL = 4000 // 4s

interface Props {
  deleteJob: (id: string) => void
  updateJob: (data: any) => void
  job: JobType
  sheetDownload: (id: string, name: string, job: JobType, jobHistory: JobType[]) => void
  jobHistory: JobType[]
  reportedJobs: string[]
  reportJob: (id: string) => void
}

const Job: React.FC<Props> = ({
  deleteJob,
  updateJob,
  job,
  sheetDownload,
  jobHistory,
  reportedJobs,
  reportJob
}) => {
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
  const exitStatuses = [
    ApiDownloadRequestStatuses.done,
    ApiDownloadRequestStatuses.partiallyFailed,
    ApiDownloadRequestStatuses.cancelled,
    ApiDownloadRequestStatuses.failed
  ]
  const [isDownloadCompleted, setIsDownloadCompleted] = useState<boolean>(false)
  const [apiStatus, setApiStatus] = useState<ApiDownloadRequestStatuses>(
    ApiDownloadRequestStatuses.pending
  )
  const [apiFormat, setApiFormat] = useState<string | undefined>(undefined)
  const [apiId, setApiId] = useState<string | undefined>(undefined)

  const getFileSizeByJobId = (jobId: string): string => {
    const relevantJob = jobHistory.find((job) => job.id === jobId)
    return relevantJob?.fileSize ? `${relevantJob?.fileSize.toString()}` : 'fileSize unavailable'
  }

  useEffect(() => {
    const {jobId, channel, dateRangeInDays, id, downloadType} = job
    if (jobHistory && id && isJobInHistory(id, jobHistory)) {
      if (downloadType === 'invoiceDeliveryExport' || downloadType === 'orderDeliveryExport') {
        trackEvent('hubExportSuccess', {
          product: 'hub',
          jobId,
          numberOfDaysSelected: dateRangeInDays,
          initiationSource: channel || 'hub',
          fileSize: getFileSizeByJobId(id)
        })
      }
      if (
        downloadType === 'bulkInvoiceDeliveryDownload' ||
        downloadType === 'bulkOrderDeliveryDownload'
      ) {
        trackEvent('hubBulkDownloadSuccess', {
          product: 'hub',
          jobId,
          numberOfDaysSelected: dateRangeInDays,
          initiationSource: channel || 'hub',
          fileSize: getFileSizeByJobId(id)
        })
      }
      setIsDownloadCompleted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobHistory])

  useEffect(() => {
    let pollApi = true
    const {jobId, channel, dateRangeInDays, name, type, fileType} = job
    const tmp = async () => {
      // eslint-disable-next-line no-unmodified-loop-condition
      while (pollApi) {
        // eslint-disable-next-line no-await-in-loop
        const {err, data: response} = await catchPromise(api.get(`/downloadRequests/${job.id}`))
        if (err) {
          updateJob({...job, status: 'requestError'})
          break
        }
        if (!response) break
        const {status: rawStatus, format} = response.data
        const status = rawStatus.toLowerCase()
        const id = response.data.id
        setApiStatus(status)
        setApiFormat(format)
        setApiId(id)

        const timer = setTimeout(() => {
          setApiStatus(ApiDownloadRequestStatuses.timedout)
          updateJob({...job, status: 'timedout'})
          pollApi = false
          trackEvent('hubDownloadTimedOut', {
            product: 'hub',
            jobId,
            numberOfDaysSelected: dateRangeInDays,
            initiationSource: channel || 'hub',
            fileSize: getFileSizeByJobId(job.id || '')
          })
        }, 60000)

        if (exitStatuses.includes(status)) {
          clearTimeout(timer)
          setIsDownloadCompleted(true)
          break
        }
        // eslint-disable-next-line no-await-in-loop
        await sleep(POLLING_INTERVAL)
      }
    }
    if (apiStatus === ApiDownloadRequestStatuses.done && apiFormat && apiId) {
      let finalName = name.split('.')
      if (finalName && apiFormat && finalName[-1] !== apiFormat) {
        finalName = [...finalName.slice(0, -1), apiFormat]
      }
      sheetDownload(apiId, finalName.join('.'), job, jobHistory)
      if (job.id && isDownloadCompleted) {
        deleteJob(job.id)
      }
    } else if (apiStatus === ApiDownloadRequestStatuses.partiallyFailed && apiFormat && apiId) {
      sheetDownload(apiId, name, job, jobHistory)
    }
    if (type !== JobEndpoint.Document && fileType !== 'hardcodedMessage') {
      void tmp()
    }

    return () => {
      pollApi = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadCompleted])

  return (
    <JobBanner
      deleteJob={deleteJob}
      job={job}
      reportedJobs={reportedJobs}
      reportJob={reportJob}
      apiStatus={apiStatus}
    />
  )
}

const mapStateToProps = (state: AppState) => ({
  jobHistory: selectJobHistory(state),
  reportedJobs: selectReportedJobs(state)
})

const mapDispatchToProps = (dispatch) => ({
  deleteJob: (id: string, name?: string) => {
    dispatch(deleteJob(id, name))
  },
  updateJob: (data) => {
    dispatch(updateJob(data))
  },
  reportJob: (id) => {
    dispatch(reportJob(id))
  },
  // eslint-disable-next-line max-params
  sheetDownload: (documentId, fileName, job, jobHistory) =>
    // eslint-disable-next-line max-params
    dispatch(sheetDownload(documentId, fileName, job, jobHistory))
})

export default connect(mapStateToProps, mapDispatchToProps)(Job)
