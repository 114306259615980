import moment from 'moment'
import {useEffect, useState} from 'react'

import {DateRange} from '../../OrderIntake/declarations/types'
import {
  areDatesSame,
  getMaximalLeadTime,
  getDayCode,
  getDifferenceFromToday,
  getLeadTimeForDay,
  getDates
} from '../../Organisms/OrderIntake/utils'

interface Timer {
  hours: number
  minutes: number
  seconds: number
}

export const useTimeLeftToOrder = (
  cutOffTime: string | undefined,
  cutOffTimeThreshold: string,
  onTimerOverChange: (isTimeOver: boolean) => void,
  deliveryDate: string,
  dateRange?: DateRange,
  timeZone?: string
) => {
  const currentTime = moment()
  const endTime = moment(cutOffTime)

  const cutOffTimeThresholdValue =
    moment.duration(cutOffTimeThreshold).hours() * 60 +
    moment.duration(cutOffTimeThreshold).minutes()
  const cutOffTimeThresholdTime = endTime.clone().subtract(cutOffTimeThresholdValue, 'minutes')
  const timeLeft = moment.duration(endTime.unix() - currentTime.unix(), 'seconds')
  const [timer, setTimer] = useState<Timer>({
    hours: timeLeft.hours(),
    minutes: timeLeft.minutes(),
    seconds: timeLeft.seconds()
  })

  const [isTimerActive, setIsTimerActive] = useState<boolean>(false)

  useEffect(() => {
    let interval: NodeJS.Timeout
    // eslint-disable-next-line complexity
    const runTimer = () => {
      const currentTime = moment()
      const timeLeft = moment.duration(endTime.unix() - currentTime.unix(), 'seconds')
      setTimer({
        hours: timeLeft.hours(),
        minutes: timeLeft.minutes(),
        seconds: timeLeft.seconds()
      })

      if (dateRange) {
        const isDeliveryDayWithinLeadTime = isDayWithinLeadTime(deliveryDate, dateRange, timeZone)

        if (isDeliveryDayWithinLeadTime) {
          if (currentTime.isBetween(cutOffTimeThresholdTime, endTime)) {
            setIsTimerActive(true)
            onTimerOverChange(false)
          } else if (currentTime.isAfter(endTime)) {
            setIsTimerActive(false)
            onTimerOverChange(true)
          } else {
            setIsTimerActive(false)
            onTimerOverChange(false)
            clearInterval(interval)
          }
        } else {
          onTimerOverChange(false)
          setIsTimerActive(false)
          clearInterval(interval)
        }
      } else {
        onTimerOverChange(true)
        setIsTimerActive(false)
        clearInterval(interval)
      }
    }
    runTimer()
    interval = setInterval(() => runTimer(), 1000)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryDate])

  return {
    isTimerActive,
    timer
  }
}

export const isDayWithinLeadTime = (
  deliveryDate: string,
  dateRange: DateRange,
  timezone: string | undefined
): boolean => {
  let result = false
  if (areDatesSame(deliveryDate, dateRange.from, timezone)) return true

  const dateRangeArray = getDates(new Date(dateRange.from), new Date(dateRange.to))
  const maximumLeadTime = getMaximalLeadTime(dateRange)

  dateRangeArray.forEach((day, index) => {
    if (index <= maximumLeadTime) {
      const availableDay = day.toISOString().split('T')[0]
      const dayCode = getDayCode(availableDay)
      const leadTime = getLeadTimeForDay(dayCode, dateRange)
      const dayDiff = getDifferenceFromToday(availableDay, timezone)

      if (dayDiff > 1 && dayDiff === leadTime) {
        if (areDatesSame(deliveryDate, availableDay, timezone)) {
          result = true
        }
      }
    }
  })

  return result
}
