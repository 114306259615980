import {Theme, makeStyles, alpha} from '@material-ui/core'

const tableRowHoverColor = '#d4eeff'
export const cutOffTimeDataCellPadding = 20
export const defaultTableCellPadding = 8

export const useOrderIntakeStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`
    },
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`
  },
  stickyContainer: {
    position: 'sticky',
    top: 65
  },
  button: {
    width: 222
  },
  fullWidth: {
    maxWidth: 'none',
    width: 215
  },
  orderButton: {
    width: '100%',
    '& button': {
      width: '100%'
    }
  },
  cancelOrderButton: {
    width: '100%',
    color: 'white',
    background: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    },
    '&:disabled': {
      color: 'white',
      background: theme.palette.action.disabled,
      pointerEvents: 'none',
      opacity: 0.7
    }
  },
  shippingAddress: {
    background: theme.palette.grey[50],
    height: '100%',
    maxWidth: '100%'
  },
  errorBox: {
    background: theme.palette.error.light
  },
  deliveryDateLabel: {
    fontSize: 14
  },
  deliveryDateIcon: {
    fontSize: 18
  },
  deliveryOrderCardPaddings: {
    paddingBottom: 19
  },
  summaryHeadlineSeparator: {
    marginTop: theme.spacing(2)
  },
  activeMaterialForm: {
    borderColor: theme.palette.primary.main,
    borderLeftColor: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.02),
    borderLeftWidth: 10,
    '& > *': {pointerEvents: 'none', opacity: 0.5}
  },
  submittedMaterialForm: {
    borderColor: theme.palette.success.main,
    borderLeftColor: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.02),
    borderLeftWidth: 10
  },
  disabledMaterialForm: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  horizontalSeparator: {
    background: theme.palette.grey[50],
    height: 2,
    width: '100%'
  },
  verticalSeparator: {
    background: theme.palette.grey[50],
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 2,
    height: '100%'
  },
  driverInstructionsDialog: {
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(4)}px`
    },
    padding: 0
  },
  confirmationSummaryItem: {
    [theme.breakpoints.up('md')]: {
      marginBottom: `${theme.spacing(4)}px`
    },
    marginBottom: `${theme.spacing(2)}px`
  },
  dropdownContainer: {
    height: '100%'
  },
  dropdownButton: {
    padding: '8px',
    borderRadius: '4px',
    backgroundColor: '#f7f7f7',
    border: '1px solid #e7e7e7',
    height: '100%'
  },
  dropdownItem: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    minHeight: '44px',
    alignItems: 'flex-start !important',
    whiteSpace: 'normal',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '6px 16px'
  },

  fullWidthFormControl: {
    width: '100%'
  },
  paperStyle: {
    [theme.breakpoints.up('md')]: {
      borderRadius: 8
    },
    borderRadius: 0,
    padding: 32
  },
  mobileMaterialView: {
    boxShadow: '0px 0px 10px 0px rgba(137, 127, 197, 0.20)',
    background: theme.palette.common.white,
    padding: 16,
    borderRadius: 6,
    marginBottom: 16
  },
  mobileMaterialViewText: {
    fontWeight: 'bold'
  },
  mobileMaterialViewMenu: {
    position: 'absolute',
    right: 32
  },
  activeMaterialRow: {
    backgroundColor: '#EBF3FC',
    '&:hover': {
      backgroundColor: `${tableRowHoverColor} !important`
    }
  },
  disabledMaterialRow: {
    borderColor: theme.palette.primary.main,
    borderLeftColor: theme.palette.primary.main,
    borderLeftWidth: 10,
    '&:hover': {
      backgroundColor: `${tableRowHoverColor} !important`
    }
  },
  materialRow: {
    '&:hover': {
      backgroundColor: `${tableRowHoverColor} !important`
    }
  },
  confirmationMaterialHeader: {
    background: '#F5F5F5',
    padding: '20px 28px'
  },
  truncatedTextRow: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}))
