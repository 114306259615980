import {useQueries} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../App.global'
import {LeadSubscriptions} from '../Molecules/LeadBanners/types'
import {Customer} from '../Organisms/Customers/Customers.types'

const fetchLeadSubscription = async (customerId: string) => {
  const params = {customerId}
  const res: AxiosResponse<LeadSubscriptions> = await api.get('/announcements/subscriptions', {
    params
  })
  return res.data
}

export const useMultipleLeads = (enabled: boolean, customers?: Customer[]) => {
  const arr = customers || []
  const leadSubsResults = useQueries({
    queries: arr.map(({customerId}) => ({
      queryKey: ['subscriptions', {type: 'c60lead', customerId}],
      queryFn: () => fetchLeadSubscription(customerId),
      enabled: enabled && Boolean(customers)
    }))
  })
  return enabled
    ? leadSubsResults.some((leadSubs) => leadSubs.data?.status !== 'cannotSubscribe')
    : false
}
