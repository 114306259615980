import {Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

import {JobType} from './AsyncJobQueue.types'
import Job from './Job'

const useStyles = makeStyles((theme: Theme) => ({
  queue: {
    boxShadow: theme.shadows[4],
    background: theme.palette.common.white,
    zIndex: 1000000,
    width: '100%'
  }
}))

interface Props {
  jobs?: JobType[]
}
const AsyncJobQueue: React.FC<Props> = ({jobs}) => {
  const classes = useStyles()
  return jobs && jobs.length ? (
    <div className={classes.queue}>
      {jobs.map((job, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Job key={`${i}-${job.id}`} job={job} />
      ))}
    </div>
  ) : null
}

export default AsyncJobQueue
