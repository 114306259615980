import {connect} from 'react-redux'

import {AppState} from '../Root.store'

import AsyncJobQueue from './AsyncJobQueue'

const mapStateToProps = (state: AppState) => ({
  jobs: state.asyncjobqueue.jobs,
  jobHistory: state.asyncjobqueue.jobHistory
})

export default connect(mapStateToProps, null)(AsyncJobQueue)
