import {History, Location} from 'history'
import {useEffect, useState} from 'react'

function getScrollPosition() {
  return {
    x: window.pageXOffset,
    y: window.pageYOffset
  }
}

function getScrollY() {
  return window.pageYOffset || document.documentElement.scrollTop
}
interface ScrollPosition {
  [key: string]: number
}

// recover saved scroll position when url changes
const useRecoverScrollPosition: {
  (history: History, location: Location): void
  pathnameToScrollPosition?: ScrollPosition
  prevPathname?: string
} = function (history, location) {
  if (
    typeof useRecoverScrollPosition.pathnameToScrollPosition === 'undefined' ||
    typeof useRecoverScrollPosition.prevPathname === 'undefined'
  ) {
    useRecoverScrollPosition.pathnameToScrollPosition = {}
    useRecoverScrollPosition.prevPathname = location.pathname
  }

  useEffect(() => {
    const unlisten = history.listen((loc) => {
      useRecoverScrollPosition.pathnameToScrollPosition![useRecoverScrollPosition.prevPathname!] =
        getScrollY()

      let scrollToY = 0
      if (loc.pathname in useRecoverScrollPosition.pathnameToScrollPosition!) {
        scrollToY = useRecoverScrollPosition.pathnameToScrollPosition![loc.pathname]
      }
      window.scrollTo(0, scrollToY)

      useRecoverScrollPosition.prevPathname = loc.pathname
    })

    return unlisten
  })
}

function useScroll() {
  const [position, setPosition] = useState(getScrollPosition())

  useEffect(() => {
    let request: number | null = null

    function handleScroll() {
      if (request === null) {
        request = window.requestAnimationFrame(() => {
          setPosition(getScrollPosition())
          request = null
        })
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  return position
}

function useXScroll() {
  const {x} = useScroll()
  return x
}

function useYScroll() {
  const {y} = useScroll()
  return y
}

export {useScroll, useXScroll, useYScroll, useRecoverScrollPosition}
